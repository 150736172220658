import React from 'react';
import Workspaces from '../components/Workspaces';
import { TitleBar } from '../components';
import { IonPage } from '@ionic/react';

const WorkspacesPage = () => {
    console.log('workspace');
    return (
        <IonPage className="page-background-color">
            <TitleBar isPrincipalMode={true} />
            <Workspaces />
        </IonPage>
    );
};
export default WorkspacesPage;
