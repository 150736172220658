import React from 'react';
import { Client } from '../lib/dataTypes';
import '../theme/components/clientAvatar.css';
import { IonAvatar, IonLabel } from '@ionic/react';
import { Spinner } from './index';

interface Props {
    data?: Client | null;
    loadingClient?: boolean;
}

const ClientAvatar = ({ data, loadingClient }: Props) => {
    console.log(data);
    return (
        <div id="client-avatar-container">
            <IonAvatar>
                <img
                    alt="profile"
                    src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
                    width={50}
                    height={50}
                />
            </IonAvatar>
            <IonLabel id="client-avatar-label">{loadingClient ? <Spinner size={18} /> : data?.name}</IonLabel>
        </div>
    );
};

export default ClientAvatar;
