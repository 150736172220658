import { Workspace } from './dataTypes/Workspace';
import { ApiClient, ApiResponse } from '@entio/api-client';
import { PaymentMethod } from './dataTypes/PaymentMethod';
import { Wallet } from './dataTypes/Wallet';
import { WalletDetails } from './dataTypes/WalletDetails';
import { BillDefinition } from './dataTypes/BillDefinition';
import { BillDefinitionDetails } from './dataTypes/BillDefinitionDetails';
import { BillDetails } from './dataTypes/BillDetails';
import { Receipt } from './dataTypes';
import { ReceiptDetails } from './dataTypes/ReceiptDetails';
import { ReceiptType } from './dataTypes/ReceiptType';
import { Bill, BillCategory, BillType, Client } from './dataTypes/index';

export type CreateWorkspaceData = { name: string; receiptPrefix: string };
export type UpdateWorkspaceData = { name?: string; receiptPrefix?: string };
export type CreateBillCategoryData = { name: string; icon: string };
export type UpdateBillCategoryData = { name?: string; icon?: string };
export type CreateClientData = { name: string; description: string };
export type UpdateClientData = { name?: string; description?: string };
export type CreateWalletData = { name: string; icon: string; initialBalance: string };
export type UpdateWalletData = { name?: string; icon?: string };
export type CreateBillDefinitionData = {
    name: string;
    description: string;
    amount: string;
    categoryId: string;
    type: BillType;
    billingDay: number;
    billingMonth: number;
    dueDay: number;
    dueMonth: number;
};
export type UpdateBillDefinitionData = {
    name?: string;
    description?: string;
    amount?: string;
    categoryId?: string;
    billingDay?: number;
    billingMonth?: number;
    dueDay?: number;
    dueMonth?: number;
};
export type CreateBillData = {
    definitionId: string;
    clientId: string;
    year: string;
};
export type CreateReceiptData = {
    billId: string;
    walletId: string;
    paymentMethodId: string;
    amount: string;
    reference: string;
    type: ReceiptType;
    comments: string;
};
export type BillsFilter = {
    /**
     * <b>When present</b>: filters the bills for the matching clientId
     */
    clientId?: string;
    pending?: boolean;
    future?: boolean;
    paid?: boolean;
};
export type ReceiptsFilter = {
    /**
     * <b>When present</b>: filters the receipts for the matching clientId
     */
    clientId?: string;
    /**
     * <b>When present</b>: filters the receipts for the matching billId
     */
    billId?: string;
    /**
     * <b>When present</b>: Most recent time, in unix timestamp format, for the receipt's creation date to be included
     */
    startTimestamp?: string;
    /**
     * <b>When present</b>: Oldest time, in unix timestamp format, for the receipt's creation date to be included
     */
    endTimestamp?: string;
};

export default class EtBillApiClient {
    baseUrl: string = process.env.REACT_APP_API_ETBILL_URL || 'https://api.entio.io/etbill/v1/dev';
    apiClient: ApiClient;
    constructor(apiClient: ApiClient = new ApiClient()) {
        this.apiClient = apiClient;
    }

    getWorkspaces = async (): Promise<ApiResponse<Workspace[]>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces`);
        return this.apiGet(endpointUrl);
    };

    getWorkspace = async (id: string): Promise<ApiResponse<Workspace>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${id}`);
        return this.apiGet(endpointUrl);
    };

    createWorkspace = async (data: CreateWorkspaceData): Promise<ApiResponse<Workspace>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces`);
        return this.apiPost(endpointUrl, data);
    };

    updateWorkspace = async (id: string, data: UpdateWorkspaceData): Promise<ApiResponse<Workspace>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${id}`);
        return this.apiPatch(endpointUrl, data);
    };

    getPaymentMethods = async (): Promise<ApiResponse<PaymentMethod[]>> => {
        const endpointUrl = this.getEndpointUrl(`/payment_methods`);
        return this.apiGet(endpointUrl);
    };

    getPaymentMethod = async (id: string): Promise<ApiResponse<PaymentMethod>> => {
        const endpointUrl = this.getEndpointUrl(`/payment_methods/${id}`);
        return this.apiGet(endpointUrl);
    };

    getClients = async (workspaceId: string): Promise<ApiResponse<Client[]>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/clients`);
        return this.apiGet(endpointUrl);
    };

    getClient = async (id: string, workspaceId: string): Promise<ApiResponse<Client>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/clients/${id}`);
        return this.apiGet(endpointUrl);
    };

    createClient = async (data: CreateClientData, workspaceId: string): Promise<ApiResponse<Client>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/clients`);
        return this.apiPost(endpointUrl, data);
    };

    updateClient = async (id: string, data: UpdateClientData, workspaceId: string): Promise<ApiResponse<Client>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/clients`);
        return this.apiPatch(endpointUrl, data);
    };

    getBillCategories = async (workspaceId: string): Promise<ApiResponse<BillCategory[]>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_categories`);
        return this.apiGet(endpointUrl);
    };

    getBillCategory = async (id: string, workspaceId: string): Promise<ApiResponse<BillCategory>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_categories/${id}`);
        return this.apiGet(endpointUrl);
    };

    createBillCategory = async (
        data: CreateBillCategoryData,
        workspaceId: string
    ): Promise<ApiResponse<BillCategory>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_categories`);
        return this.apiPost(endpointUrl, data);
    };

    updateBillCategory = async (
        id: string,
        data: UpdateBillCategoryData,
        workspaceId: string
    ): Promise<ApiResponse<BillCategory>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_categories/${id}`);
        return this.apiPatch(endpointUrl, data);
    };

    getWallets = async (workspaceId: string): Promise<ApiResponse<Wallet[]>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/wallets`);
        return this.apiGet(endpointUrl);
    };

    getWallet = async (id: string, workspaceId: string): Promise<ApiResponse<WalletDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/wallets/${id}`);
        return this.apiGet(endpointUrl);
    };

    createWallet = async (data: CreateWalletData, workspaceId: string): Promise<ApiResponse<WalletDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/wallets`);
        return this.apiPost(endpointUrl, data);
    };

    updateWallet = async (id: string, data: UpdateWalletData, workspaceId: string): Promise<ApiResponse<Wallet>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/wallets/${id}`);
        return this.apiPatch(endpointUrl, data);
    };

    getBillDefinitions = async (workspaceId: string): Promise<ApiResponse<BillDefinition[]>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_definitions`);
        return this.apiGet(endpointUrl);
    };

    getBillDefinition = async (id: string, workspaceId: string): Promise<ApiResponse<BillDefinitionDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_definitions/${id}`);
        return this.apiGet(endpointUrl);
    };

    createBillDefinition = async (
        data: CreateBillDefinitionData,
        workspaceId: string
    ): Promise<ApiResponse<BillDefinitionDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_definitions`);
        return this.apiPost(endpointUrl, data);
    };

    updateBillDefinition = async (
        id: string,
        data: UpdateBillDefinitionData,
        workspaceId: string
    ): Promise<ApiResponse<BillDefinitionDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bill_definitions/${id}`);
        return this.apiPatch(endpointUrl, data);
    };

    getBills = async (filter: BillsFilter, workspaceId: string): Promise<ApiResponse<Bill[]>> => {
        const queryParams = EtBillApiClient.toQueryParams(filter);
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bills?${queryParams}`);
        return this.apiGet(endpointUrl);
    };

    getBill = async (id: string, workspaceId: string): Promise<ApiResponse<BillDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bills/${id}`);
        return this.apiGet(endpointUrl);
    };

    createBill = async (data: CreateBillData, workspaceId: string): Promise<ApiResponse<BillDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/bills`);
        return this.apiPost(endpointUrl, data);
    };

    getReceipts = async (filter: ReceiptsFilter, workspaceId: string): Promise<ApiResponse<Receipt[]>> => {
        const queryParams = EtBillApiClient.toQueryParams(filter);
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/receipts?${queryParams}`);
        return this.apiGet(endpointUrl);
    };

    getReceipt = async (id: string, workspaceId: string): Promise<ApiResponse<ReceiptDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/receipts/${id}`);
        return this.apiGet(endpointUrl);
    };

    createReceipt = async (data: CreateReceiptData, workspaceId: string): Promise<ApiResponse<ReceiptDetails>> => {
        const endpointUrl = this.getEndpointUrl(`/workspaces/${workspaceId}/receipts`);
        return this.apiPost(endpointUrl, data);
    };

    wait = async (millis = 2000) => {
        return new Promise((resolve) => {
            setTimeout(resolve, millis);
        });
    };

    private getEndpointUrl = (path: string) => {
        if (path?.startsWith('/')) {
            path = path.substring(1);
        }

        return `${this.baseUrl}/${path}`;
    };

    private static toQueryParams(params: Record<string, unknown>) {
        let query = '';
        for (const entry of Object.entries(params)) {
            if (entry.length < 2) {
                continue;
            }

            const key = entry[0];
            const value = encodeURIComponent(entry[1] as string);
            query += `${key}=${value}&`;
        }

        if (query.length > 0) {
            query = query.substr(0, query.length - 1);
        }

        return query;
    }

    private apiGet = async <T>(endpointUrl: string) => {
        return this.apiClient.apiFetchWithSession<T>(endpointUrl, {});
    };

    private apiPost = async <T>(endpointUrl: string, data: Record<string, unknown>) => {
        return this.apiClient.apiFetchWithSession<T>(endpointUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    };

    private apiPatch = async <T>(endpointUrl: string, data: Record<string, unknown>) => {
        return this.apiClient.apiFetchWithSession<T>(endpointUrl, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    };
}
