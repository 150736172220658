import React from 'react';
import { LoginPage } from './pages/auth';
import { IonRouterOutlet } from '@ionic/react';
import { Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import SessionValidator from './components/auth/SessionValidator';
import { HomePage, ClientDetailsPage, BillDetailsPage, ReceiptDetailsPage } from './pages';
import WorkspaceValidator from './components/WorkspaceValidator';
import WorkspacesPage from './pages/WorkspacesPage';

const { PUBLIC_URL } = process.env;
const baseUrl = PUBLIC_URL || '/';

const Router = () => {
    return (
        <IonReactRouter basename={baseUrl}>
            <SessionValidator>
                <WorkspaceValidator />
            </SessionValidator>
            <IonRouterOutlet>
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/workspaces" component={WorkspacesPage} />
                <Route exact path="/home" component={HomePage} />
                <Route exact path="/" component={HomePage} />
                <Route exact path="/client/:id" component={ClientDetailsPage} />
                <Route exact path="/receipt/:id" component={ReceiptDetailsPage} />
                <Route exact path="/bill/:id" component={BillDetailsPage} />
                {/* TODO default redirect causes trouble with useParams hook, check this problem later
                    <Redirect exact path="/" to="/home" />
                */}
            </IonRouterOutlet>
        </IonReactRouter>
    );
};

export default Router;
