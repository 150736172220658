import { Menu } from 'react-ionicons';
import React, { useEffect, useState } from 'react';
import { ClientAvatar } from '../components';
import { useParams } from 'react-router-dom';
import EtBillApiClient from '../lib/EtBillApiClient';
import { IonContent, IonItem, IonLabel, useIonToast } from '@ionic/react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setReceipt, selectReceipts } from '../reducers/ReceiptsReducer';
import moment from 'moment-timezone';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import Spinner from './Spinner';
import '../theme/components/receiptDetails.css';

const etbillApiClient = new EtBillApiClient();

const ReceiptDetails = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams<{ id: string }>();
    const { receipt } = useAppSelector(selectReceipts);
    const { workspace } = useAppSelector(getWorkspace);
    const [loadingReceipt, setLoadingReceipt] = useState(false);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();

    useEffect(() => {
        const getReceipt = async (id: string) => {
            setLoadingReceipt(true);
            const result = await etbillApiClient.getReceipt(id, workspaceId);
            if (result.success) {
                console.log(result.data);
                dispatch(setReceipt(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
            setLoadingReceipt(false);
        };

        getReceipt(id).then();
    }, [id, dispatch]);

    if (loadingReceipt) {
        return (
            <IonContent>
                <div className="content loadSpinner">
                    <Spinner />
                </div>
            </IonContent>
        );
    }

    return (
        <>
            <div className="content">
                {/*TODO get user info*/}
                <ClientAvatar data={{ id: 'sad', name: 'isaacs', description: 'fdsfdf', pictureUrl: '' }} />
            </div>
            <IonContent>
                <div className="content">
                    <div>
                        <IonItem className="ion-item1">
                            <Menu color="black" />
                            <div>
                                <IonLabel>
                                    Calle 189
                                    <br />
                                    Mantenimiento April 2021
                                </IonLabel>
                            </div>
                            <div className="ion-item">
                                <IonLabel>${moment(receipt?.date).format('DD-MM-YYYY')}</IonLabel>
                                <IonLabel>${receipt?.amount}</IonLabel>
                            </div>
                        </IonItem>
                    </div>
                    <div>
                        <p>
                            Folio: <span>${receipt?.receiptNumber}</span>
                        </p>
                        <p>
                            Metodo de pago: <span>${receipt?.type}</span>
                        </p>
                        <p>
                            Pagado: <span>${receipt?.amount}</span>
                        </p>
                    </div>
                </div>
            </IonContent>
        </>
    );
};

export default ReceiptDetails;
