import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Workspace } from '../lib/dataTypes/Workspace';
import { useAppDispatch } from '../app/hooks';
import '../theme/components/workspaces.css';
import { Spinner } from './index';
import { setWorkspace } from '../reducers/WorkspaceReducer';
import { IonButton, IonContent, IonFooter, IonItem, IonLabel, useIonToast } from '@ionic/react';
import EtBillApiClient from '../lib/EtBillApiClient';

const Workspaces = () => {
    const [workspaces, setWorkspaces] = useState<Workspace[]>([]);
    const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>('');
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const [present] = useIonToast();

    useEffect(() => {
        const apiClient = new EtBillApiClient();
        const loadWorkspace = async () => {
            setLoading(true);
            const resp = await apiClient.getWorkspaces();
            setLoading(false);
            if (resp.success) {
                const data = [
                    {
                        id: '',
                        name: '',
                    },
                    ...resp.data,
                ];

                setWorkspaces(data);
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: resp.message,
                    duration: 2000,
                });
            }
        };

        loadWorkspace().then();
    }, []);

    const onWorkspaceSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        console.log(`selected ${value}`);
        setCurrentWorkspaceId(value);
    };

    const saveWorkspace = () => {
        const resp = workspaces.find(({ id }) => id === currentWorkspaceId);
        if (resp) {
            dispatch(setWorkspace(resp));
            history.push('/home');
        }
    };

    const options = () =>
        workspaces.map(({ id, name }) => (
            <option key={id} value={id} className="option">
                {name}
            </option>
        ));

    // TODO uncomment when we have a spinner
    // if (loading) {
    //     return (
    //         <AppContainer>
    //             <Loading />;
    //         </AppContainer>
    //     );
    // }

    return (
        <IonContent>
            <IonItem className="workspace-title">
                <IonLabel>Seleccione un lugar</IonLabel>
            </IonItem>
            <IonItem className="workspace-content">
                <select value={currentWorkspaceId} onChange={onWorkspaceSelected}>
                    {options()}
                </select>
                {loading && <Spinner />}
            </IonItem>
            <IonFooter>
                <IonButton className="workspace-button" onClick={saveWorkspace}>
                    Aceptar
                </IonButton>
            </IonFooter>
        </IonContent>
    );
};

export default Workspaces;
