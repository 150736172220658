import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Workspace } from '../lib/dataTypes/Workspace';

export interface WorkspaceState {
    workspace?: Workspace;
}

function loadCurrentWorkspace(): Workspace | undefined {
    const strCurrentWorkspace = localStorage.getItem('currentWorkspace');
    return strCurrentWorkspace && JSON.parse(strCurrentWorkspace);
}

const initialState: WorkspaceState = {
    workspace: loadCurrentWorkspace(),
};

export const WorkspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        setWorkspace: (state, action: PayloadAction<Workspace>) => {
            const workspace = action.payload;
            if (workspace) {
                localStorage.setItem('currentWorkspace', JSON.stringify(workspace));
            } else {
                localStorage.removeItem('currentWorkspace');
            }

            Object.assign(state, {
                workspace,
            });
        },
    },
});

export const { setWorkspace } = WorkspaceSlice.actions;

export const getWorkspace = (state: RootState) => state.workspace;

export default WorkspaceSlice.reducer;
