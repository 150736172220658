import React from 'react';
import { IonContent, IonInfiniteScroll, IonPage } from '@ionic/react';
import { DetailsDown, DetailsUp } from './index';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import '../../theme/components/billDetails.css';
import { getWorkspace } from '../../reducers/WorkspaceReducer';

const BillDetails = () => {
    const { id } = useParams<{ id: string }>();
    const { workspace } = useAppSelector(getWorkspace);

    const workspaceId = workspace?.id || '';
    return (
        <IonContent>
            <IonInfiniteScroll>
                <DetailsUp id={id} workspaceId={workspaceId} />
                <DetailsDown id={id} workspaceId={workspaceId} />
            </IonInfiniteScroll>
        </IonContent>
    );
};

export default BillDetails;
