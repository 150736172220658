import React from 'react';
import { getOnValidateSession } from '@entio/accounts-react-sdk';
import { Location } from 'history';
import { Redirect, useLocation } from 'react-router-dom';

export interface Props {
    children?: React.ReactElement;
}

const SessionValidator = (props: Props) => {
    console.log('Validating session...');
    const location: Location = useLocation();
    const onValidateSession = getOnValidateSession();
    const sourcePath = location.pathname;
    const pathIsLogin = sourcePath === '/login';
    if (pathIsLogin || (onValidateSession && onValidateSession())) {
        if (pathIsLogin) {
            console.log('Ignoring session validation for /login path');
            return <></>;
        } else {
            console.log('Session is valid!');
            return <>{props.children}</>;
        }
    }

    console.log('Session not valid, redirecting...');
    const loginPath = '/login';
    const path = `${loginPath}?source_path=${sourcePath}`;
    return <Redirect to={path} />;
};

export default React.memo(SessionValidator);
