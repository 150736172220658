import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Client } from '../lib/dataTypes';

interface ClientSelector {
    value: string | null;
    label: string;
}

export interface ClientsState {
    clients: Client[];
    client: Client | null;
    clientSelector: ClientSelector;
}

export const initialClientSelectorState = {
    value: null,
    label: 'Buscar cliente',
};

const initialState: ClientsState = {
    clients: [],
    client: null,
    clientSelector: initialClientSelectorState,
};

export const ClientSlice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClients: (state, action: PayloadAction<Client[]>) => {
            Object.assign(state, {
                clients: action.payload,
            });
        },
        setClientSelector: (state, action: PayloadAction<ClientSelector>) => {
            Object.assign(state, {
                clientSelector: action.payload,
            });
        },
        setClient: (state, action: PayloadAction<Client | null>) => {
            Object.assign(state, {
                client: action.payload,
            });
        },
    },
});

export const { setClients, setClient, setClientSelector } = ClientSlice.actions;

export const selectClients = (state: RootState) => state.clients;

export default ClientSlice.reducer;
