import BillList from './BillList';
import { Bill } from '../lib/dataTypes';
import { IonItem, IonLabel, useIonToast } from '@ionic/react';
import React, { useEffect, useState } from 'react';
import EtBillApiClient from '../lib/EtBillApiClient';
import { useAppSelector } from '../app/hooks';
import { getWorkspace } from '../reducers/WorkspaceReducer';

interface Props {
    clientId: string;
    onItemSelected: (item: Bill) => void;
}

const BillSelector = ({ clientId, onItemSelected }: Props) => {
    const [bills, setBills] = useState<Bill[]>();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const { workspace } = useAppSelector(getWorkspace);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();

    useEffect(() => {
        const apiClient = new EtBillApiClient();
        const load = async () => {
            const result = await apiClient.getBills({ clientId, future: true }, workspaceId);
            if (result.success) {
                setBills(result.data);
                setErrorMessage('');
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
                setErrorMessage(result.message);
            }
        };

        load().then();
    }, [clientId]);

    if (errorMessage) {
        return (
            <>
                <div>
                    <IonItem>
                        <IonLabel>{errorMessage}</IonLabel>
                    </IonItem>
                </div>
            </>
        );
    }

    return (
        <>
            <BillList
                data={bills}
                onItemClick={(bill) => {
                    onItemSelected && onItemSelected(bill);
                }}
            />
        </>
    );
};

export default BillSelector;
