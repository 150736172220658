import React from 'react';
import { BillDetails } from '../components/billDetails';
import { IonPage } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import { TitleBar } from '../components';

const BillDetailsPage = () => {
    const history = useHistory();
    return (
        <IonPage>
            <TitleBar
                isBackMode={true}
                arrowBackClick={() => {
                    history.goBack();
                }}
            />
            <BillDetails />
        </IonPage>
    );
};

export default BillDetailsPage;
