import React from 'react';
import { IonPage } from '@ionic/react';
import { useAppDispatch } from '../app/hooks';
import { setBills } from '../reducers/BillsReducer';
import { useHistory, useParams } from 'react-router';
import { ClientDetails, TitleBar } from '../components';
import { setClientSelector, setClient, initialClientSelectorState } from '../reducers/ClientsReducer';

const ClientDetailsPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    return (
        <IonPage className="page-background-color">
            <TitleBar
                isBackMode={true}
                arrowBackClick={() => {
                    dispatch(setClientSelector(initialClientSelectorState));
                    dispatch(setClient(null));
                    dispatch(setBills([]));
                    history.push('/home');
                }}
            />
            <ClientDetails />
        </IonPage>
    );
};

export default ClientDetailsPage;
