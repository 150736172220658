import React from 'react';
import { getOnValidateSession } from '@entio/accounts-react-sdk';
import { Location } from 'history';
import { Redirect, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { getWorkspace } from '../reducers/WorkspaceReducer';

export interface Props {
    children?: React.ReactElement;
}

const WorkspaceValidator = (props: Props) => {
    console.log('Validating workspace...');
    const { workspace } = useAppSelector(getWorkspace);
    if (workspace) {
        console.log('Workspace is valid');
        return <>{props.children}</>;
    }

    console.log('No workspace found');
    return <Redirect to="/workspaces" />;
};

export default React.memo(WorkspaceValidator);
