import React from 'react';
import { IonSpinner } from '@ionic/react';

interface Props {
    size?: number;
    color?: string;
}

export default function Spinner({ size, color }: Props) {
    return <IonSpinner style={{ width: size || 50, color: color || '#3880ff' }} />;
}
