import { Bill } from '../lib/dataTypes';
import { useHistory } from 'react-router';
import '../theme/components/clientDetails.css';
import React, { useEffect, useState } from 'react';
import EtBillApiClient from '../lib/EtBillApiClient';
import { CheckboxChangeEventDetail } from '@ionic/core';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectBills, setBills } from '../reducers/BillsReducer';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import { add, arrowBack, close, person } from 'ionicons/icons';
import { selectClients, setClient } from '../reducers/ClientsReducer';
import { Receipt as ReceiptIcon } from 'react-ionicons';
import { Reader } from 'react-ionicons';
import { PersonAddOutline } from 'react-ionicons';
import { ClientSelector, ClientAvatar, BillList, BillSelector, NewBillForm, Spinner } from '../components';
import {
    IonContent,
    IonButton,
    IonCheckbox,
    IonFab,
    IonFabButton,
    IonFabList,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonList,
    useIonToast,
} from '@ionic/react';

const etbillApiClient = new EtBillApiClient();

const ClientDetails = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { client, clientSelector } = useAppSelector(selectClients);
    const { bills } = useAppSelector(selectBills);
    const [loadingClient, setLoadingClient] = useState(false);
    const [loadingBills, setLoadingBills] = useState(false);
    const { workspace } = useAppSelector(getWorkspace);
    const [errorMessage, setErrorMessage] = useState<string>();
    const [showPending, setShowPending] = useState<boolean>(true);
    const [isBillSelectorOpen, setIsBillSelectorOpen] = useState<boolean>(false);
    const [isBillDefinitionSelectorOpen, setIsBillDefinitionSelectorOpen] = useState<boolean>(false);

    const workspaceId = workspace?.id || '';
    const id = clientSelector?.value || '';
    const [present] = useIonToast();

    useEffect(() => {
        const getClient = async (id: string) => {
            setLoadingClient(true);
            const result = await etbillApiClient.getClient(id, workspaceId);
            if (result.success) {
                dispatch(setClient(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
                setErrorMessage(result.message);
            }
            setLoadingClient(false);
        };

        if (id) {
            getClient(id).then();
        } else {
            history.push('/home');
        }
    }, [history, id, dispatch, workspaceId]);

    useEffect(() => {
        const getBills = async (id: string) => {
            setLoadingBills(true);
            const filter = { clientId: id, paid: true, pending: showPending };
            const result = await etbillApiClient.getBills(filter, workspaceId);

            if (result.success) {
                dispatch(setBills(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
                setErrorMessage(result.message);
            }
            setLoadingBills(false);
        };

        if (id) {
            getBills(id).then();
        }
    }, [id, dispatch, showPending, workspaceId]);

    if (!id) {
        return <></>;
    }

    if (errorMessage) {
        return <>{errorMessage}</>;
    }

    const onClientSelected = async (id: string) => {
        if (!id) return;
        history.push(`/client/${id}`);
    };

    const hideBillSelector = () => setIsBillSelectorOpen(false);
    const showBillSelector = () => setIsBillSelectorOpen(true);

    const hideBillDefinitionSelector = () => setIsBillDefinitionSelectorOpen(false);
    const showBillDefinitionSelector = () => setIsBillDefinitionSelectorOpen(true);

    const onShowPendingCheckChange = (event: CustomEvent<CheckboxChangeEventDetail>) => {
        const checked = event.detail.checked;
        setShowPending(checked);
    };

    const onBillSelected = async (bill: Bill) => {
        history.push(`/bill/${bill.id}`);
    };

    return (
        <>
            <div className="content">
                <ClientSelector onItemSelected={onClientSelected} />
                <ClientAvatar data={client} loadingClient={loadingClient} />
                <div id="client-title-container">
                    <h6 id="last-accounting-title" className="color-text">
                        Estado de Cuenta
                        <span className="SpinnerContent">{loadingBills && <Spinner size={12} />}</span>
                    </h6>

                    <h6 id="checkbox-container" className="color-text">
                        <span id="checkbox">
                            <IonCheckbox
                                style={{ width: 12, height: 12 }}
                                color="success"
                                className="checkbox"
                                checked={showPending}
                                onIonChange={onShowPendingCheckChange}
                                name="Pagos Futuros"
                                title="Pagos por realizar"
                            />
                        </span>
                        Incluir Pagos por realizar
                    </h6>
                </div>
            </div>
            <IonContent>
                <div className="content">
                    <BillList data={bills} onItemClick={onBillSelected} />
                </div>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon icon={add} />
                    </IonFabButton>
                    <IonFabList side="top">
                        <IonFabButton onClick={showBillSelector} title="Proximos cargos" color="primary">
                            <div>
                                <Reader color="white" width="80%" />
                            </div>
                        </IonFabButton>
                        <IonFabButton onClick={showBillDefinitionSelector} title="Asignar un cargo" color="primary">
                            <div>
                                <PersonAddOutline color="white" width="40%" />
                                <ReceiptIcon color="white" width="40%" />
                            </div>
                        </IonFabButton>
                    </IonFabList>
                </IonFab>
                <IonModal isOpen={isBillSelectorOpen} onDidDismiss={hideBillSelector}>
                    <IonContent>
                        <IonItem>
                            <IonLabel>Próximos pagos a realizar</IonLabel>
                        </IonItem>
                        <IonList className="bill_modal">
                            <BillSelector clientId={id} onItemSelected={onBillSelected} />
                        </IonList>
                        <IonButton onClick={hideBillSelector} className="close_future" size="small">
                            <IonIcon icon={close} color="white" size="small" />
                        </IonButton>
                    </IonContent>
                </IonModal>
                {isBillDefinitionSelectorOpen && (
                    <IonModal isOpen={isBillDefinitionSelectorOpen} onDidDismiss={hideBillDefinitionSelector}>
                        <IonContent className="modal">
                            <IonItem>
                                <IonLabel>Asignar un nuevo cargo</IonLabel>
                                <IonButton onClick={hideBillDefinitionSelector}>
                                    <IonIcon icon={close} size="small" />
                                </IonButton>
                            </IonItem>
                            <NewBillForm clientId={id} />
                        </IonContent>
                    </IonModal>
                )}
            </IonContent>
        </>
    );
};

export default ClientDetails;
