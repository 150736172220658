import React from 'react';
import { UserDataResult } from '@entio/accounts-sdk';
import { LoginButton, LoginHandler } from '@entio/accounts-react-sdk';

import { login } from '../../reducers/AuthReducer';
import { useAppDispatch } from '../../app/hooks';

const LoginPage = () => {
    const dispatch = useAppDispatch();

    const onSuccess = async (data: UserDataResult) => {
        const { user: userData, profile, token } = data;
        dispatch(login({ user: userData, profile, token }));
    };

    const onError = async (error: string) => {
        console.log(error);
    };

    return (
        <LoginHandler onSuccess={onSuccess} onError={onError}>
            <LoginButton />
        </LoginHandler>
    );
};

export default LoginPage;
