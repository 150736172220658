import React, { useEffect, useState } from 'react';
import {
    IonCard,
    IonCardContent,
    IonCol,
    IonGrid,
    IonRow,
    IonLabel,
    IonInput,
    IonSelectOption,
    IonSelect,
    IonButton,
    useIonToast,
} from '@ionic/react';
import { InputChangeEventDetail, SelectChangeEventDetail } from '@ionic/core';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import { selectBills } from '../../reducers/BillsReducer';
import './../../theme/components/billDetails.css';
import EtBillApiClient, { CreateReceiptData } from '../../lib/EtBillApiClient';
import {
    selectCatalogs,
    setCurrentPaymentMethodId,
    setCurrentWalletId,
    setPaymentMethods,
    setWallets,
} from '../../reducers/CatalogsReducer';
import { PaymentMethod } from '../../lib/dataTypes/PaymentMethod';
import { Wallet } from '../../lib/dataTypes/Wallet';
import { ReceiptType } from '../../lib/dataTypes/ReceiptType';

const etBillApiClient = new EtBillApiClient();

interface Props {
    id: string;
    workspaceId: string;
}

const DetailsDown = ({ id, workspaceId }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { bill } = useAppSelector(selectBills);
    const { wallets, paymentMethods } = useAppSelector(selectCatalogs);

    const [reference, setReference] = useState('');
    const [loading, setLoading] = useState(false);

    const [amount, setAmount] = useState(bill?.balance || '');

    const [present] = useIonToast();

    const handleAmount = (e: CustomEvent<InputChangeEventDetail>) => setAmount(e?.detail?.value || '');

    const { currentPaymentMethodId, currentWalletId } = useAppSelector(selectCatalogs);

    const handleReference = (e: CustomEvent<InputChangeEventDetail>) => setReference(e?.detail?.value || '');

    const createReceipt = async () => {
        const data: CreateReceiptData = {
            billId: id,
            walletId: currentWalletId,
            reference,
            paymentMethodId: currentPaymentMethodId,
            type: ReceiptType.RECEIVED,
            comments: '',
            amount,
        };

        if (currentPaymentMethodId.length === 0) {
            present({
                color: 'danger',
                position: 'top',
                message: 'Debe ingresar tipo de cobro',
                duration: 2000,
            });
            return;
        }

        if (currentWalletId.length === 0) {
            present({
                color: 'danger',
                position: 'top',
                message: 'Debe ingresar wallet',
                duration: 2000,
            });
            return;
        }

        const response = await etBillApiClient.createReceipt(data, workspaceId);
        setLoading(true);

        if (response.success) {
            setLoading(false);
            history.push(`/receipt/${response.data?.id}`);
        } else {
            setLoading(false);
            present({
                color: 'danger',
                position: 'top',
                message: response.message,
                duration: 2000,
            });
        }
    };

    useEffect(() => {
        const getWallets = async () => {
            const response = await etBillApiClient.getWallets(workspaceId);

            if (response.success) {
                dispatch(setWallets(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };

        const getPaymentMethods = async () => {
            const response = await etBillApiClient.getPaymentMethods();

            if (response.success) {
                dispatch(setPaymentMethods(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };
        getWallets().then();
        getPaymentMethods().then();
    }, []);

    const selectWallet = (e: CustomEvent<SelectChangeEventDetail>) => {
        const value = e?.detail?.value || '';

        dispatch(setCurrentWalletId(value));
    };

    const selectPaymentMethod = (e: CustomEvent<SelectChangeEventDetail>) => {
        const value = e?.detail?.value || '';

        dispatch(setCurrentPaymentMethodId(value));
    };

    const renderWalletSelector = () => {
        if (!wallets) return <></>;
        return (
            <IonSelect onIonChange={selectWallet} className="data-bill selected" disabled={loading}>
                {wallets.map((wallet: Wallet) => {
                    return (
                        <IonSelectOption key={wallet.id} value={wallet.id}>
                            <img src={wallet.icon} alt="icon" width="20px" />
                            {wallet.name}
                        </IonSelectOption>
                    );
                })}
            </IonSelect>
        );
    };

    const renderPaymentMethodSelector = () => {
        if (!paymentMethods) return <></>;
        return (
            <IonSelect onIonChange={selectPaymentMethod} className="data-bill selected" disabled={loading}>
                {paymentMethods.map((paymentMethod: PaymentMethod) => {
                    return (
                        <IonSelectOption key={paymentMethod.id} value={paymentMethod.id}>
                            <img src={paymentMethod.icon} alt="icon" width="20px" /> {paymentMethod.name}
                        </IonSelectOption>
                    );
                })}
            </IonSelect>
        );
    };

    if (!bill) {
        return <></>;
    }
    return (
        <IonCard className="ion-card">
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol className="cash-icon">$</IonCol>
                        <IonCol>
                            <IonInput
                                value={amount}
                                onIonChange={handleAmount}
                                className="data-bill amount"
                                disabled={loading}
                            />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonRow>
                                <IonCol>
                                    <IonLabel position="stacked" className="labels">
                                        Tipo de cobro
                                    </IonLabel>
                                    {renderPaymentMethodSelector()}
                                </IonCol>
                                <IonCol>
                                    <IonLabel position="stacked" className="labels">
                                        Wallet
                                    </IonLabel>
                                    {renderWalletSelector()}
                                </IonCol>
                            </IonRow>
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonLabel position="stacked" className="labels">
                                Referencia
                            </IonLabel>
                            <IonInput
                                className="data-bill"
                                onIonChange={handleReference}
                                value={reference}
                                disabled={loading}
                            />
                        </IonCol>
                    </IonRow>

                    <IonRow>
                        <IonCol>
                            <IonButton expand="block" color="success" onClick={createReceipt} disabled={loading}>
                                Guardar pago
                            </IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    );
};

export default DetailsDown;
