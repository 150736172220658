import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Receipt } from '../lib/dataTypes';

export interface ReceiptsState {
    receipts: Receipt[];
    receipt: null | Receipt;
}

const initialState: ReceiptsState = {
    receipts: [],
    receipt: null,
};

export const ReceiptsSlice = createSlice({
    name: 'receipts',
    initialState,
    reducers: {
        setReceipts: (state, action: PayloadAction<Receipt[]>) => {
            Object.assign(state, {
                receipts: action.payload,
            });
        },
        setReceipt: (state, action: PayloadAction<Receipt | null>) => {
            Object.assign(state, {
                receipt: action.payload,
            });
        },
    },
});

export const { setReceipts, setReceipt } = ReceiptsSlice.actions;

export const selectReceipts = (state: RootState) => state.receipts;

export default ReceiptsSlice.reducer;
