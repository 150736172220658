import React from 'react';
import moment from 'moment-timezone';
import { Receipt } from '../lib/dataTypes';
import '../theme/components/receiptListItem.css';
import { Receipt as ReceiptIcon } from 'react-ionicons';
import { IonLabel, IonItem, IonNote } from '@ionic/react';

interface Props {
    data: Receipt;
    onItemSelected: (id: string) => void;
}

const ReceiptListItem = ({ data, onItemSelected }: Props) => {
    return (
        <IonItem
            className="receipt-item-container"
            onClick={() => {
                onItemSelected(data.id);
            }}>
            <IonNote slot="start" className="receipt-item-icon" mode="md">
                <ReceiptIcon color="grey" height="17px" width="17px" />
            </IonNote>
            <IonNote className="receipt-item-data" mode="md">
                <IonLabel>{data.bill.client.name}</IonLabel>
                <IonLabel>{data.bill.name}</IonLabel>
            </IonNote>
            <IonNote slot="end" mode="md" className="receipt-item-date">
                <IonLabel>{moment(data.date).format('DD/MM/YYYY')}</IonLabel>
                <IonLabel>${data.amount}</IonLabel>
            </IonNote>
        </IonItem>
    );
};

export default ReceiptListItem;
