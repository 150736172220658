import React from 'react';
import { IonPage } from '@ionic/react';
import { Home, TitleBar } from '../components';

const HomePage = () => {
    return (
        <IonPage className="page-background-color">
            <TitleBar isPrincipalMode={true} />
            <Home />
        </IonPage>
    );
};

export default HomePage;
