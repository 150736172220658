import React from 'react';
import { IonList } from '@ionic/react';
import { Bill } from '../lib/dataTypes';
import { BillListItem } from './index';

interface Props {
    data: Bill[] | undefined;
    onItemClick?: (bill: Bill) => void;
}

const BillList = ({ data, onItemClick }: Props) => {
    return (
        <IonList className="list">
            {data?.map((bill: Bill) => {
                return <BillListItem key={bill.id} data={bill} onClick={() => onItemClick && onItemClick(bill)} />;
            })}
        </IonList>
    );
};

export default BillList;
