import React, { useEffect, useState } from 'react';
import EtBillApiClient from '../lib/EtBillApiClient';
import { IonItem, useIonToast } from '@ionic/react';
import { BillDefinition } from '../lib/dataTypes/BillDefinition';
import { BillType } from '../lib/dataTypes';
import { useAppSelector } from '../app/hooks';
import { getWorkspace } from '../reducers/WorkspaceReducer';

interface Props {
    value?: BillDefinition;
    onItemSelected: (item?: BillDefinition) => void;
}

const BillSelector = ({ onItemSelected, value }: Props) => {
    const [billDefinitions, setBillDefinitions] = useState<BillDefinition[]>();
    const [currentDefinition, setCurrentDefinition] = useState<BillDefinition | undefined>(value);
    if (currentDefinition?.id !== value?.id) {
        setCurrentDefinition(value);
    }

    const [present] = useIonToast();

    const { workspace } = useAppSelector(getWorkspace);
    const workspaceId = workspace?.id || '';
    useEffect(() => {
        const apiClient = new EtBillApiClient();
        const load = async () => {
            const result = await apiClient.getBillDefinitions(workspaceId);
            if (result.success) {
                setBillDefinitions([
                    {
                        id: '',
                        name: '',
                        category: {
                            id: '',
                            name: '',
                            icon: '',
                        },
                        type: BillType.RECEIVABLE,
                        description: '',
                    },
                    ...result.data,
                ]);
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
        };

        load().then();
    }, [workspaceId]);

    const onBillDefinitionSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const id = event.target.value;
        const selectedItem = (id && billDefinitions?.find((item) => item.id === id)) || undefined;
        setCurrentDefinition(selectedItem);
        onItemSelected(selectedItem);
        console.log('New bill definition!', selectedItem?.id);
    };

    return (
        <IonItem>
            <select onChange={onBillDefinitionSelected} value={currentDefinition?.id || ''}>
                {billDefinitions?.map((definition) => {
                    return (
                        <option key={definition.id} value={definition.id}>
                            {definition.name}
                        </option>
                    );
                })}
            </select>
        </IonItem>
    );
};

export default BillSelector;
