import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Profile from '@entio/accounts-sdk/dist/types/Profile';
import User from '@entio/accounts-sdk/dist/types/User';
import { RootState } from '../app/store';

export interface SessionState {
    token: string;
    loggedIn?: boolean;
    user: User;
    profile: Profile;
    userId?: string;
}
const getItem = (key: string) => localStorage.getItem(key) || '';

export const INITIAL_SESSION_STATE = (): SessionState => {
    const token = getItem('token');
    const user = getItem('currentUser');
    const profile = getItem('currentProfile');
    const userId = getItem('userId');
    return {
        token,
        loggedIn: !!token,
        user: JSON.parse(user || '{}'),
        profile: JSON.parse(profile || '{}'),
        userId: userId,
    };
};

const initialState = INITIAL_SESSION_STATE();

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<SessionState>) => {
            const { user, profile, token } = action.payload;
            if (!token || !user || !profile) {
                Object.assign(state, INITIAL_SESSION_STATE());
            }
            const userId = user.id;
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('currentProfile', JSON.stringify(profile));
            localStorage.setItem('userId', userId);
            localStorage.setItem('token', token);
            Object.assign(state, {
                token,
                loggedIn: !!token,
                user: user,
                profile: profile,
                userId: userId,
            });
        },
        logout: (state) => {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('currentProfile');
            localStorage.removeItem('token');
            localStorage.removeItem('userId');

            Object.assign(state, INITIAL_SESSION_STATE());
        },
    },
});

export const { login, logout } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
