import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { AuthReducer, ReceiptsReducer, ClientsReducer, BillsReducer, CatalogsReducer } from '../reducers';
import WorkspaceReducer from '../reducers/WorkspaceReducer';

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        receipts: ReceiptsReducer,
        clients: ClientsReducer,
        bills: BillsReducer,
        catalogs: CatalogsReducer,
        workspace: WorkspaceReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
