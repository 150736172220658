import React from 'react';
import { IonList } from '@ionic/react';
import { ReceiptListItem } from './index';
import { Receipt } from '../lib/dataTypes';

interface Props {
    data: Receipt[];
    onItemSelected: (id: string) => void;
}

const ReceiptList = ({ data, onItemSelected }: Props) => {
    return (
        <IonList className="list">
            {data.map((receipt: Receipt) => {
                return <ReceiptListItem key={receipt.id} data={receipt} onItemSelected={onItemSelected} />;
            })}
        </IonList>
    );
};

export default ReceiptList;
