import React from 'react';
import '../theme/components/titleBar.css';
import ENTIO from '../../src/images/ENTIO.png';
import { ArrowBackOutline } from 'react-ionicons';
import { IonHeader, IonToolbar, IonTitle } from '@ionic/react';

interface Props {
    isBackMode?: boolean;
    isPrincipalMode?: boolean;
    arrowBackClick?: () => void;
}

const TitleBar = ({ isPrincipalMode, isBackMode, arrowBackClick }: Props) => {
    return (
        <IonHeader mode="md">
            <IonToolbar id="header">
                {isPrincipalMode && (
                    <>
                        <img alt="etbill-logo" id="nav-image" src={ENTIO} />
                        <IonTitle id="nav-title" size="large">
                            etbill
                        </IonTitle>
                    </>
                )}

                {isBackMode && (
                    <ArrowBackOutline color={'white'} onClick={arrowBackClick} style={{ cursor: 'pointer' }} />
                )}
            </IonToolbar>
        </IonHeader>
    );
};

export default TitleBar;
