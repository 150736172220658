import '../theme/components/home.css';
import React, { useEffect, useState } from 'react';
import { IonContent, useIonToast } from '@ionic/react';
import { useHistory } from 'react-router';
import { Spinner } from './index';
import EtBillApiClient from '../lib/EtBillApiClient';
import { ClientSelector, ReceiptList } from '../components';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { setReceipts, selectReceipts } from '../reducers/ReceiptsReducer';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import '../theme/components/home.css';

const etbillApiClient = new EtBillApiClient();

const Home = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [loadingReceipts, setLoadingReceipts] = useState(false);
    const { receipts } = useAppSelector(selectReceipts);
    const { workspace } = useAppSelector(getWorkspace);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();

    useEffect(() => {
        const getReceipt = async () => {
            setLoadingReceipts(true);
            const result = await etbillApiClient.getReceipts({}, workspaceId);
            if (result.success) {
                dispatch(setReceipts(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
            setLoadingReceipts(false);
        };

        getReceipt().then();
    }, [dispatch, workspaceId]);

    const onReceiptSelected = (id: string) => {
        if (!id) {
            return;
        }
        history.push(`/receipt/${id}`);
    };

    const onClientSelected = (id: string) => {
        if (!id) {
            return;
        }
        history.push(`/client/${id}`);
    };

    return (
        <>
            <div className="content">
                <ClientSelector onItemSelected={onClientSelected} />
                <h6 id="last-movements-title" className="color-text">
                    Últimos Movimientos
                    <span className="SpinnerContent">{loadingReceipts && <Spinner size={12} />}</span>
                </h6>
            </div>
            <IonContent>
                <div className="content">
                    {receipts.length ? (
                        <ReceiptList data={receipts} onItemSelected={onReceiptSelected} />
                    ) : (
                        !loadingReceipts && <h6 className="noReceipt">No tienes movimientos</h6>
                    )}
                </div>
            </IonContent>
        </>
    );
};

export default Home;
