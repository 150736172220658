import { Search } from 'react-ionicons';
import React, { useEffect, useState } from 'react';
import { useIonToast } from '@ionic/react';
import { Client } from '../lib/dataTypes';
import Select, { components } from 'react-select';
import EtBillApiClient from '../lib/EtBillApiClient';
import { getWorkspace } from '../reducers/WorkspaceReducer';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { selectClients, setClients, setClientSelector } from '../reducers/ClientsReducer';
import { Spinner } from './index';

const etbillApiClient = new EtBillApiClient();

const selectStyles = {
    container: (styles: { [key: string]: unknown }) => {
        return {
            ...styles,
            height: 28,
            minHeight: 25,
            color: 'secondary',
        };
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    control: (styles: any) => {
        return {
            ...styles,
            alignItems: 'center',
            backgroundColor: 'white',
            borderColor: 'rgba(212, 205, 212, 0.5)',
            borderStyle: 'solid',
            borderWidth: 1,
            color: 'text.secondary',
            fontfamily: 'Montserrat',
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            label: 'control',
            borderRadius: 0,
            padding: 0,
            height: 28,
            minHeight: 25,
            fontSize: 'small',
            outline: 'none',
            boxSizing: 'border-box',
            position: 'relative',
            transition: 'all 100ms',
        };
    },
    input: (styles: { [key: string]: unknown }) => {
        return {
            ...styles,
            '&:hover': { borderColor: 'grey' },
        };
    },
    indicatorsContainer: () => {
        return {
            backgroundColor: '#188fff',
            display: 'flex',
            alignItems: 'center',
            height: 28,
            paddingTop: 1,
            margin: '-1px -1px 0 0',
            color: 'text.secondary',
        };
    },
    indicatorSeparator: () => {
        return {};
    },
    menu: (styles: { [key: string]: unknown }) => {
        return {
            ...styles,
            backgroundColor: 'white',
            fontSize: 'small',
            color: 'text.secondary',
        };
    },
};

interface Props {
    onItemSelected: (id: string) => void;
}
const ClientSelector = ({ onItemSelected }: Props) => {
    const dispatch = useAppDispatch();
    const { clients, clientSelector } = useAppSelector(selectClients);
    const { workspace } = useAppSelector(getWorkspace);
    const [loadingClients, setLoadingClients] = useState(false);
    const workspaceId = workspace?.id || '';

    const [present] = useIonToast();

    useEffect(() => {
        const getClients = async () => {
            setLoadingClients(true);
            const result = await etbillApiClient.getClients(workspaceId);
            if (result.success) {
                dispatch(setClients(result.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: result.message,
                    duration: 2000,
                });
            }
            setLoadingClients(false);
        };

        getClients().then();
    }, [dispatch]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                {loadingClients ? (
                    <span>
                        <Spinner size={22} color={'white'} />
                    </span>
                ) : (
                    <Search color="white" />
                )}
            </components.DropdownIndicator>
        );
    };

    const clientSelectFormat = clients.map((client: Client) => {
        return { value: client.id, label: client.name };
    });

    return (
        <>
            <Select
                value={clientSelector}
                options={clientSelectFormat}
                isSearchable
                components={{ DropdownIndicator }}
                styles={selectStyles}
                placeholder="Buscar cliente"
                noOptionsMessage={() => 'No se encontró clientes'}
                onChange={(event) => {
                    dispatch(
                        setClientSelector({
                            value: (event && event.value) || null,
                            label: (event && event.label) || '',
                        })
                    );
                    onItemSelected((event && event.value) || '');
                }}
            />
        </>
    );
};

export default ClientSelector;
