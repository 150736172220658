import React from 'react';
import Decimal from 'decimal.js';
import { Bill } from '../lib/dataTypes';
import '../theme/components/receiptListItem.css';
import { Receipt as ReceiptIcon } from 'react-ionicons';
import { IonLabel, IonItem, IonNote } from '@ionic/react';
import moment from 'moment-timezone';

interface Props {
    data: Bill;
    onClick?: () => void;
}

const BillListItem = ({ data, onClick }: Props) => {
    console.log(data);
    const balance = new Decimal(data.balance).eq(0) ? 'Pagado' : data.balance;
    return (
        <IonItem onClick={onClick} className="receipt-item-container">
            <IonNote slot="start" className="receipt-item-icon" mode="md">
                <ReceiptIcon color="white" height="17px" width="17px" />
            </IonNote>
            <IonNote className="receipt-item-data" mode="md">
                <IonLabel>{data.name}</IonLabel>
            </IonNote>
            <IonNote slot="end" mode="md" className="receipt-item-date">
                <IonLabel>{moment(data.dueDate).format('DD/MM/YYYY')}</IonLabel>
                <IonLabel color={balance === 'Pagado' ? 'success' : 'danger'}>
                    {balance === 'Pagado' ? balance : `$${balance}`}
                </IonLabel>
            </IonNote>
        </IonItem>
    );
};

export default BillListItem;
