import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useHistory } from 'react-router-dom';
import { setBill, selectBills } from '../../reducers/BillsReducer';
import EtBillApiClient from '../../lib/EtBillApiClient';
import { IonCard, IonCardContent, IonGrid, IonRow, IonCol, IonLabel, useIonToast } from '@ionic/react';
import moment from 'moment-timezone';
import { selectReceipts, setReceipts } from '../../reducers/ReceiptsReducer';
import { ReceiptList } from '../index';

const etBillApiClient = new EtBillApiClient();

interface Props {
    id: string;
    workspaceId: string;
}

const DetailsUp = ({ id, workspaceId }: Props) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const { bill } = useAppSelector(selectBills);
    const { receipts } = useAppSelector(selectReceipts);

    const [present] = useIonToast();

    useEffect(() => {
        const getBill = async (id: string) => {
            const response = await etBillApiClient.getBill(id, workspaceId);

            if (response.success) {
                dispatch(setBill(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };

        getBill(id).then();
    }, [dispatch]);

    useEffect(() => {
        const getReceipts = async (id: string) => {
            const filter = { billId: id };
            const response = await etBillApiClient.getReceipts(filter, workspaceId);

            if (response.success) {
                dispatch(setReceipts(response.data));
            } else {
                present({
                    color: 'danger',
                    position: 'top',
                    message: response.message,
                    duration: 2000,
                });
            }
        };

        getReceipts(id).then();
    }, [dispatch]);

    const onReceiptSelected = (id: string) => {
        if (!id) {
            return;
        }
        history.push(`/receipt/${id}`);
    };

    const dueDate = moment(bill?.dueDate).format('YYYY-MM-DD');

    return (
        <IonCard className="ion-card">
            {bill && (
                <IonCardContent>
                    <IonGrid className="pay-details">
                        <IonRow>
                            <IonCol className="client-inf">
                                <img src={bill?.client?.pictureUrl} />
                            </IonCol>
                            <IonCol className="client-inf">{bill?.client?.name}</IonCol>
                        </IonRow>
                        <IonRow className="separation">
                            <IonCol className="title-column title-pay">Pago por realizar</IonCol>
                            <IonCol className="data-column">Vencimiento: {dueDate}</IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol className="title-column">{bill.name}</IonCol>
                            <IonCol className="data-column">${bill.total}</IonCol>
                        </IonRow>
                        <IonRow className="separation">
                            <IonCol />
                            <IonCol className="data-column">Por pagar ${bill.balance}</IonCol>
                        </IonRow>
                    </IonGrid>
                    <IonGrid>
                        <IonRow>
                            <IonLabel className="labels">Recibos</IonLabel>
                        </IonRow>
                        <ReceiptList data={receipts} onItemSelected={onReceiptSelected} />
                    </IonGrid>
                </IonCardContent>
            )}
        </IonCard>
    );
};

export default DetailsUp;
