import React from 'react';
import { IonPage } from '@ionic/react';
import { ReceiptDetails, TitleBar } from '../components';
import { setReceipt } from '../reducers/ReceiptsReducer';
import { useHistory } from 'react-router';
import { useAppDispatch } from '../app/hooks';

const ReceiptDetailsPage = () => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    return (
        <IonPage className="page-background-color">
            <TitleBar
                isBackMode={true}
                arrowBackClick={() => {
                    dispatch(setReceipt(null));
                    history.push('/home');
                }}
            />
            <ReceiptDetails />
        </IonPage>
    );
};

export default ReceiptDetailsPage;
