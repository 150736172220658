import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Wallet } from '../lib/dataTypes/Wallet';
import { PaymentMethod } from '../lib/dataTypes/PaymentMethod';

export interface Catalogs {
    wallets: Wallet[];
    paymentMethods: PaymentMethod[];
    currentWalletId: string;
    currentPaymentMethodId: string;
}

const initialState: Catalogs = {
    wallets: [],
    paymentMethods: [],
    currentWalletId: '',
    currentPaymentMethodId: '',
};

export const CatalogsSlice = createSlice({
    name: 'catalogs',
    initialState,
    reducers: {
        setWallets: (state, action: PayloadAction<Wallet[]>) => {
            Object.assign(state, { wallets: action.payload });
        },
        setPaymentMethods: (state, action: PayloadAction<PaymentMethod[]>) => {
            Object.assign(state, { paymentMethods: action.payload });
        },
        setCurrentPaymentMethodId: (state, action: PayloadAction<string>) => {
            Object.assign(state, { currentPaymentMethodId: action.payload });
        },
        setCurrentWalletId: (state, action: PayloadAction<string>) => {
            Object.assign(state, { currentWalletId: action.payload });
        },
    },
});

export const { setWallets, setPaymentMethods, setCurrentPaymentMethodId, setCurrentWalletId } = CatalogsSlice.actions;

export const selectCatalogs = (state: RootState) => state.catalogs;

export default CatalogsSlice.reducer;
